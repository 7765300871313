var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "refFormObserver"
  }, [_c('div', [_c('div', {
    staticClass: "d-flex align-items-center mb-50"
  }, [_c('div', {
    staticClass: "mr-50"
  }, [_c('AddPassenger', {
    attrs: {
      "pax-lists": _vm.passengerDataToAdd
    },
    on: {
      "changeQuantityPax": function changeQuantityPax(val) {
        return _vm.$emit('multipleQuantityPaxChange', val);
      }
    }
  })], 1), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(234, 84, 85, 0.15)',
      expression: "'rgba(234, 84, 85, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "py-50 px-2 d-flex-center rounded-lg",
    attrs: {
      "id": "id-icon-quick-input-multiple-pax",
      "variant": "gradient"
    },
    on: {
      "click": _vm.handleShowModalQuickInput
    }
  }, [_c('feather-icon', {
    staticClass: "text-white",
    attrs: {
      "icon": "ZapIcon",
      "size": "17"
    }
  })], 1)], 1), _vm._l(_vm.passengerDataToAdd, function (passenger, index) {
    return _c('b-card', {
      key: index,
      staticClass: "rounded-lg mb-50 border",
      attrs: {
        "header-class": "px-50 pb-50 pt-0",
        "no-body": ""
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('b-row', {
            staticClass: "w-100 mx-0 add_spacing",
            attrs: {
              "no-gutters": ""
            }
          }, [_c('div', {
            staticClass: "font-weight-bolder w-100 d-flex-between"
          }, [_c('div', {
            staticClass: "d-flex"
          }, [_c('span', {
            staticClass: "text-nowrap mr-2"
          }, [_vm._v(" " + _vm._s(index + 1) + ". " + _vm._s(_vm.$t("reservation.".concat(passenger.paxType)))), _c('span', {
            staticClass: "text-danger"
          }, [_vm._v("*")]), _vm._v(": ")]), _c('b-form-checkbox', {
            attrs: {
              "id": "Check_SN-".concat(passenger.paxType, "-").concat(index),
              "disabled": ['INFANT'].includes(passenger.paxType) || ['CHILD'].includes(passenger.paxType) && ['1G'].includes(_vm.airlineSearch)
            },
            model: {
              value: passenger.isSetBirthday,
              callback: function callback($$v) {
                _vm.$set(passenger, "isSetBirthday", $$v);
              },
              expression: "passenger.isSetBirthday"
            }
          }, [_vm._v(" NS ")])], 1), _c('div', {
            staticClass: "d-flex justify-content-end w-100"
          }, [_c('b-button', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: "collapse-".concat(passenger.paxType, "-").concat(index),
              expression: "`collapse-${passenger.paxType}-${index}`"
            }, {
              name: "ripple",
              rawName: "v-ripple.400",
              value: 'rgba(47, 165, 229, 0.2)',
              expression: "'rgba(47, 165, 229, 0.2)'",
              modifiers: {
                "400": true
              }
            }],
            staticClass: "px-50 py-25 mr-2",
            attrs: {
              "variant": "flat-primary"
            }
          }, [_c('span', {
            staticClass: "font-small-3 ml-25"
          }, [_vm._v(" " + _vm._s(_vm.$t('flight.Details')) + " ")]), _c('feather-icon', {
            attrs: {
              "icon": "ChevronDownIcon",
              "size": "16"
            }
          })], 1), _c('b-button', {
            staticClass: "px-50 py-0",
            attrs: {
              "variant": "flat-danger"
            },
            on: {
              "click": function click($event) {
                return _vm.handleDeletePax(passenger);
              }
            }
          }, [_c('feather-icon', {
            attrs: {
              "icon": "XIcon",
              "size": "16"
            }
          })], 1)], 1)]), _c('b-col', {
            attrs: {
              "cols": "3"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Honorific",
              "rules": "required",
              "vid": "Honorific-".concat(passenger.paxType, "-").concat(index)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  staticClass: "mb-0",
                  attrs: {
                    "size": "sm",
                    "state": _vm.getValidationState(validationContext) === false ? false : null
                  }
                }, [_c('v-select', {
                  staticClass: "honorific-select fw-700",
                  attrs: {
                    "id": "Honorific-".concat(passenger.paxType, "-").concat(index),
                    "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                    "options": ['ADULT'].includes(passenger.paxType) ? [].concat(_vm.titleOptions[passenger.paxType], _vm.titleOptions['CHILD']) : _vm.titleOptions[passenger.paxType],
                    "label": "text",
                    "clearable": false,
                    "placeholder": "".concat(_vm.$t('flight.Honorific'), " (*)"),
                    "reduce": function reduce(val) {
                      return val.value;
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "option",
                    fn: function fn(data) {
                      return [_c('span', {
                        staticClass: "font-small-4"
                      }, [_vm._v(" " + _vm._s(_vm.$t(data.text)) + " ")])];
                    }
                  }, {
                    key: "selected-option",
                    fn: function fn(data) {
                      return [_c('span', {
                        staticClass: "text-nowrap font-medium-1"
                      }, [_vm._v(" " + _vm._s(_vm.$t(data.text)) + " ")])];
                    }
                  }], null, true),
                  model: {
                    value: passenger.title,
                    callback: function callback($$v) {
                      _vm.$set(passenger, "title", $$v);
                    },
                    expression: "passenger.title"
                  }
                }), validationContext.errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "target": "title-".concat(passenger.paxType, "-").concat(index),
                    "triggers": "hover focus",
                    "placement": "top",
                    "boundary": "viewport",
                    "variant": "danger",
                    "title": validationContext.errors[0]
                  }
                }, [_c('span', {
                  staticClass: "text-white py-25 mb-0"
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
              }
            }], null, true)
          })], 1), _c('b-col', {
            attrs: {
              "cols": "4"
            }
          }, [_c('validation-provider', {
            attrs: {
              "rules": "required|min:1|alphaSpaces",
              "name": "Last Name",
              "vid": "lastName-".concat(passenger.paxType, "-").concat(index)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('vue-autosuggest', {
                  attrs: {
                    "id": "lastName-".concat(passenger.paxType, "-").concat(index),
                    "suggestions": passenger.paxType ? _vm.paxFilteredSuggestions[passenger.paxType] : [],
                    "input-props": {
                      placeholder: "".concat(_vm.$t('flight.lastName'), " (*)"),
                      state: "".concat(_vm.getValidationState(validationContext) === false ? false : null),
                      class: "form-control text-body ".concat(_vm.getValidationState(validationContext) === false ? 'is-invalid' : ''),
                      style: 'font-size: 17px !important; font-weight: 700 !important'
                    },
                    "section-configs": _vm.sectionConfigs,
                    "get-suggestion-value": function getSuggestionValue(suggestion) {
                      return _vm.getSuggestionValue(suggestion, 'lastName');
                    }
                  },
                  on: {
                    "focus": function focus($event) {
                      return _vm.setCurrentPaxIndex(index);
                    },
                    "input": function input($event) {
                      passenger.lastName = _vm.formatterInput($event);
                      _vm.debounceSearchCustomerContact(passenger.lastName);
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref) {
                      var suggestion = _ref.suggestion;
                      return [_c('div', {
                        staticClass: "cursor-pointer"
                      }, [_c('b-row', [_c('b-col', {
                        staticClass: "text-nowrap font-italic",
                        attrs: {
                          "cols": "12"
                        }
                      }, [_vm._v(" " + _vm._s(suggestion.item.type ? _vm.$t("reservation.".concat(suggestion.item.type)) : '') + " ")]), _c('b-col', {
                        attrs: {
                          "cols": "12"
                        }
                      }, [_c('b', {
                        domProps: {
                          "innerHTML": _vm._s(_vm.highlightSearchString(_vm.getLocaleFullName(suggestion.item), passenger.lastName))
                        }
                      })]), _c('b-col', {
                        staticClass: "text-nowrap",
                        attrs: {
                          "cols": "12"
                        }
                      }, [_vm._v(" " + _vm._s(suggestion.item.birthDay ? suggestion.item.birthDay.slice(0, 10) : '') + " ")])], 1)], 1)];
                    }
                  }], null, true),
                  model: {
                    value: passenger.lastName,
                    callback: function callback($$v) {
                      _vm.$set(passenger, "lastName", $$v);
                    },
                    expression: "passenger.lastName"
                  }
                }), validationContext.errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "target": "lastName-".concat(passenger.paxType, "-").concat(index),
                    "triggers": "hover focus",
                    "placement": "top",
                    "boundary": "viewport",
                    "variant": "danger",
                    "title": validationContext.errors[0]
                  }
                }, [_c('span', {
                  staticClass: "text-white py-25 mb-0"
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()];
              }
            }], null, true)
          })], 1), _c('b-col', {
            attrs: {
              "cols": "5"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "First Name",
              "rules": "required|min:1|alphaSpaces",
              "vid": "firstName-".concat(passenger.paxType, "-").concat(index)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('vue-autosuggest', {
                  attrs: {
                    "id": "firstName-".concat(passenger.paxType, "-").concat(index),
                    "suggestions": passenger.paxType ? _vm.paxFilteredSuggestions[passenger.paxType] : [],
                    "input-props": {
                      placeholder: "".concat(_vm.$t('flight.firstName'), " (*)"),
                      state: "".concat(_vm.getValidationState(validationContext) === false ? false : null),
                      class: "form-control text-body ".concat(_vm.getValidationState(validationContext) === false ? 'is-invalid' : ''),
                      style: 'font-size: 17px !important; font-weight: 700 !important'
                    },
                    "section-configs": _vm.sectionConfigs,
                    "get-suggestion-value": function getSuggestionValue(suggestion) {
                      return _vm.getSuggestionValue(suggestion, 'firstName');
                    }
                  },
                  on: {
                    "focus": function focus($event) {
                      return _vm.setCurrentPaxIndex(index);
                    },
                    "input": function input($event) {
                      passenger.firstName = _vm.formatterInput($event);
                      _vm.debounceSearchCustomerContact(passenger.firstName);
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref2) {
                      var suggestion = _ref2.suggestion;
                      return [_c('div', {
                        staticClass: "cursor-pointer"
                      }, [_c('b-row', [_c('b-col', {
                        staticClass: "text-nowrap font-italic small",
                        attrs: {
                          "cols": "12"
                        }
                      }, [_vm._v(" " + _vm._s(suggestion.item.type ? _vm.$t("reservation.".concat(suggestion.item.type)) : '') + " ")]), _c('b-col', {
                        attrs: {
                          "cols": "12"
                        }
                      }, [_c('b', {
                        domProps: {
                          "innerHTML": _vm._s(_vm.highlightSearchString(_vm.getLocaleFullName(suggestion.item), passenger.firstName))
                        }
                      })]), _c('b-col', {
                        staticClass: "text-nowrap",
                        attrs: {
                          "cols": "12"
                        }
                      }, [_vm._v(" " + _vm._s(suggestion.item.birthDay ? suggestion.item.birthDay.slice(0, 10) : '') + " ")])], 1)], 1)];
                    }
                  }], null, true),
                  model: {
                    value: passenger.firstName,
                    callback: function callback($$v) {
                      _vm.$set(passenger, "firstName", $$v);
                    },
                    expression: "passenger.firstName"
                  }
                }), validationContext.errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "target": "firstName-".concat(passenger.paxType, "-").concat(index),
                    "triggers": "hover focus",
                    "placement": "top",
                    "boundary": "viewport",
                    "variant": "danger",
                    "title": validationContext.errors[0]
                  }
                }, [_c('span', {
                  staticClass: "text-white py-25 mb-0"
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()];
              }
            }], null, true)
          })], 1), passenger.isSetBirthday ? _c('b-col', {
            attrs: {
              "cols": "12"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": _vm.$t('flight.dob'),
              "rules": passenger.paxType === 'ADULT' && ['MSTR', 'MISS'].includes(passenger.title) ? _vm.getValidateBirthday('CHILD') : _vm.getValidateBirthday(passenger.paxType),
              "vid": "dob-".concat(passenger.paxType, "-").concat(passenger.paxId)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  staticClass: "mb-0",
                  staticStyle: {
                    "max-width": "250px"
                  },
                  attrs: {
                    "id": "bd-".concat(passenger.paxType, "-").concat(passenger.paxId)
                  }
                }, [_c('flat-pickr', {
                  staticClass: "d-none",
                  model: {
                    value: passenger.birthday,
                    callback: function callback($$v) {
                      _vm.$set(passenger, "birthday", $$v);
                    },
                    expression: "passenger.birthday"
                  }
                }), _c('div', {
                  staticClass: "form-control p-0",
                  class: validationContext.errors[0] ? 'is-invalid' : ''
                }, [_c('flat-pickr', {
                  staticClass: "form-control fw-700 px-50 py-0",
                  style: "height: '30px'; font-size: '17px !important'",
                  attrs: {
                    "id": "dob-".concat(passenger.paxType, "-").concat(passenger.paxId),
                    "name": _vm.$t('flight.dob'),
                    "config": Object.assign({}, _vm.configFlatPickr, {
                      altInputClass: 'form-control text-body input fw-700 px-50 py-0 border-transparent'
                    }),
                    "placeholder": "".concat(_vm.$t('flight.dob'), " ").concat(_vm.getValidateBirthday(passenger.paxType).includes('required') ? '(*)' : '')
                  },
                  model: {
                    value: passenger.birthday,
                    callback: function callback($$v) {
                      _vm.$set(passenger, "birthday", $$v);
                    },
                    expression: "passenger.birthday"
                  }
                })], 1), validationContext.errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "target": "bd-".concat(passenger.paxType, "-").concat(passenger.paxId),
                    "triggers": "hover focus",
                    "placement": "top",
                    "boundary": "viewport",
                    "variant": "danger",
                    "title": validationContext.errors[0]
                  }
                }, [_c('span', {
                  staticClass: "text-white py-25 mb-0"
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
              }
            }], null, true)
          })], 1) : _vm._e()], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c('b-collapse', {
      staticClass: "mb-50",
      attrs: {
        "id": "collapse-".concat(passenger.paxType, "-").concat(index),
        "visible": passenger.paxType === 'INFANT' ? true : false
      }
    }, [_c('b-row', {
      staticClass: "my-25",
      attrs: {
        "no-gutters": ""
      }
    }, [passenger.paxType === 'INFANT' ? _c('b-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "parentId",
        "rules": passenger.paxType === 'INFANT' ? "required|duplicateParentId:".concat(_vm.parentSelected.toString()) : '',
        "vid": "parent-id-select-".concat(passenger.paxId)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-form-group', {
            staticClass: "mb-0 ml-50",
            attrs: {
              "state": _vm.getValidationState(validationContext) === false ? false : null
            }
          }, [passenger.paxType === 'INFANT' ? _c('v-select', {
            staticClass: "fw-700",
            staticStyle: {
              "max-width": "250px"
            },
            attrs: {
              "id": "parent-id-select-".concat(passenger.paxId),
              "options": _vm.parentOptions,
              "reduce": function reduce(val) {
                return val.paxId;
              },
              "clearable": false,
              "placeholder": "".concat(_vm.$t('flight.selectAdult'), " (*)"),
              "label": "firstName"
            },
            scopedSlots: _vm._u([{
              key: "option",
              fn: function fn(data) {
                return [_c('span', {
                  staticClass: "font-small-3"
                }, [_vm._v(" " + _vm._s(data.firstName && data.lastName ? "".concat(data.title, " ").concat(data.lastName, " ").concat(data.firstName) : "".concat(data.paxType)) + " ")])];
              }
            }, {
              key: "selected-option",
              fn: function fn(data) {
                return [_c('span', {
                  staticClass: "font-small-3"
                }, [_vm._v(" " + _vm._s(data.firstName && data.lastName ? "".concat(data.title, " ").concat(data.lastName, " ").concat(data.firstName) : data.paxType ? "".concat(data.paxType) : 'Chọn người lớn đi cùng (*)') + " ")])];
              }
            }], null, true),
            model: {
              value: passenger.parentPaxId,
              callback: function callback($$v) {
                _vm.$set(passenger, "parentPaxId", $$v);
              },
              expression: "passenger.parentPaxId"
            }
          }) : _vm._e(), validationContext.errors[0] ? _c('b-tooltip', {
            attrs: {
              "target": "parent-id-select-".concat(passenger.paxId),
              "triggers": "hover focus",
              "placement": "top",
              "boundary": "viewport",
              "variant": "danger",
              "title": validationContext.errors[0]
            }
          }, [_c('span', {
            staticClass: "text-white py-25 mb-0"
          }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e(), _c('div', {
            staticClass: "pt-25"
          }, [_c('i', {
            staticClass: "text-warning font-small-4"
          }, [_c('span', {
            staticClass: "text-danger"
          }, [_vm._v("*")]), _vm._v(" " + _vm._s(_vm.$t('flight.requiredAdult')) + " ")])])], 1)];
        }
      }], null, true)
    })], 1) : _vm._e(), passenger.paxType !== 'INFANT' && !_vm.isEmpty(_vm.selectedTrip.flat()) && _vm.selectedTrip.flat().some(function (t) {
      return !!t && ['VN'].includes(t.airline);
    }) ? _c('b-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "Member Fly",
        "rules": "",
        "vid": "memberFly-".concat(passenger.paxType, "-").concat(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-input-group', {
            staticClass: "border rounded d-flex",
            attrs: {
              "id": "memberFly-".concat(passenger.paxType, "-").concat(index)
            }
          }, [_c('b-input-group-prepend', {
            staticClass: "flex-nowrap",
            attrs: {
              "id": "memberFly-".concat(passenger.paxType, "-").concat(index)
            }
          }, [_c('v-select', {
            staticClass: "membership-airline-select border-right",
            class: _vm.isMobileView ? 'border-secondary' : '',
            staticStyle: {
              "min-width": "180px"
            },
            attrs: {
              "id": "memberFly-".concat(passenger.paxType, "-").concat(index),
              "options": _vm.getAirlineOptionsGroupByTrips(index),
              "label": "text",
              "clearable": false,
              "placeholder": "".concat(_vm.$t('flight.airline')),
              "reduce": function reduce(val) {
                return val.value;
              }
            },
            scopedSlots: _vm._u([{
              key: "option",
              fn: function fn(data) {
                return [_c('span', {
                  staticClass: "font-small-3 my-25 font-weight-bold"
                }, [_vm._v(" " + _vm._s(data.text) + " ")])];
              }
            }, {
              key: "selected-option",
              fn: function fn(data) {
                return [_c('span', {
                  staticClass: "font-small-3 font-weight-bolder text-nowrap"
                }, [_vm._v(" " + _vm._s(data.text) + " ")])];
              }
            }], null, true),
            model: {
              value: passenger.memberFly.airline,
              callback: function callback($$v) {
                _vm.$set(passenger.memberFly, "airline", $$v);
              },
              expression: "passenger.memberFly.airline"
            }
          })], 1), _c('b-form-input', {
            staticClass: "border-transparent fw-700",
            staticStyle: {
              "min-width": "145px"
            },
            attrs: {
              "id": "cardNumber-".concat(passenger.paxType, "-").concat(index),
              "type": "number",
              "placeholder": "".concat(_vm.$t('flight.memberFly')),
              "lazy-formatter": "",
              "formatter": _vm.formatterCardNumber,
              "state": _vm.getValidationState(validationContext) === false ? false : null
            },
            on: {
              "change": function change(val) {
                return passenger.memberFly.cardNumber = Number(val);
              }
            },
            model: {
              value: passenger.memberFly.cardNumber,
              callback: function callback($$v) {
                _vm.$set(passenger.memberFly, "cardNumber", $$v);
              },
              expression: "passenger.memberFly.cardNumber"
            }
          })], 1)];
        }
      }], null, true)
    })], 1) : _vm._e()], 1), !_vm.isDomesticTrips && passenger.paxType !== 'INFANT' ? _c('span', {
      staticClass: "fw-700 ml-50"
    }, [_vm._v(" Hộ chiếu ")]) : _vm._e(), !_vm.isDomesticTrips && passenger.paxType !== 'INFANT' ? _c('b-row', {
      staticClass: "px-25",
      attrs: {
        "no-gutters": ""
      }
    }, [_c('b-col', {
      staticClass: "px-25 pl-lg-0 mt-50 pr-lg-50",
      attrs: {
        "md": "3",
        "lg": "6"
      }
    }, [_c('validation-provider', {
      attrs: {
        "rules": _vm.getValidatePassport(),
        "name": _vm.$t('flight.Passport'),
        "vid": "passport-".concat(passenger.paxType, "-").concat(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-form-input', {
            attrs: {
              "id": "passport-".concat(passenger.paxType, "-").concat(index),
              "state": _vm.getValidationState(validationContext) === false ? false : null,
              "placeholder": "".concat(_vm.$t('flight.Passport'), " ").concat(_vm.getValidatePassport().includes('required') ? '(*)' : '')
            },
            model: {
              value: passenger.PaxDocuments[0].number,
              callback: function callback($$v) {
                _vm.$set(passenger.PaxDocuments[0], "number", $$v);
              },
              expression: "passenger.PaxDocuments[0].number"
            }
          })];
        }
      }], null, true)
    })], 1), _c('b-col', {
      staticClass: "px-50 mt-50 px-lg-0",
      attrs: {
        "md": "3",
        "lg": "6"
      }
    }, [_c('validation-provider', {
      attrs: {
        "rules": _vm.getValidatePassport(),
        "name": _vm.$t('flight.Nationality'),
        "vid": "nationality-".concat(passenger.paxType, "-").concat(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-form-group', {
            staticClass: "mb-0",
            attrs: {
              "size": "sm"
            }
          }, [_c('vue-autosuggest', {
            attrs: {
              "id": "nationality-".concat(passenger.paxType, "-").concat(index),
              "suggestions": _vm.nationalSuggestion,
              "input-props": {
                placeholder: 'Mã quốc gia',
                state: "".concat(_vm.getValidationState(validationContext) === false ? false : null),
                maxlength: '30',
                class: "form-control"
              },
              "section-configs": _vm.nationalConfigs,
              "get-suggestion-value": function getSuggestionValue(suggestion) {
                return _vm.getSuggestionNationalValue(suggestion);
              }
            },
            on: {
              "focus": function focus($event) {
                return _vm.handleFocusNationalSuggestion(index);
              },
              "input": function input($event) {
                return _vm.inputHandle(passenger.PaxDocuments[0].nationality);
              }
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref3) {
                var suggestion = _ref3.suggestion;
                return [_c('div', {
                  staticClass: "cursor-pointer"
                }, [_c('b-row', [_c('b-col', {
                  attrs: {
                    "cols": "12"
                  }
                }, [_c('b', [_vm._v(_vm._s(suggestion.item.countryName))])]), _c('b-col', {
                  staticClass: "text-nowrap",
                  attrs: {
                    "cols": "12"
                  }
                }, [_vm._v(" ("), _c('span', {
                  staticClass: "text-warning font-weight-bolder"
                }, [_vm._v(_vm._s(suggestion.item.longCode))]), _vm._v(") ")])], 1)], 1)];
              }
            }], null, true),
            model: {
              value: passenger.PaxDocuments[0].nationality,
              callback: function callback($$v) {
                _vm.$set(passenger.PaxDocuments[0], "nationality", $$v);
              },
              expression: "passenger.PaxDocuments[0].nationality"
            }
          })], 1)];
        }
      }], null, true)
    })], 1), _c('b-col', {
      staticClass: "px-25 mt-50 pr-lg-50",
      attrs: {
        "md": "3",
        "lg": "6"
      }
    }, [_c('validation-provider', {
      attrs: {
        "rules": _vm.getValidatePassport(),
        "name": "Nơi cấp",
        "vid": "passport-place-issued-".concat(passenger.paxType, "-").concat(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('vue-autosuggest', {
            attrs: {
              "id": "passport-place-issued-".concat(passenger.paxType, "-").concat(index),
              "suggestions": _vm.nationalSuggestion,
              "input-props": {
                placeholder: 'Nơi cấp',
                state: "".concat(_vm.getValidationState(validationContext) === false ? false : null),
                maxlength: '30',
                class: "form-control"
              },
              "section-configs": _vm.placeIssuedConfigs,
              "get-suggestion-value": function getSuggestionValue(suggestion) {
                return _vm.getSuggestionNationalValueNationalName(suggestion);
              }
            },
            on: {
              "focus": function focus($event) {
                return _vm.getSuggestionNationalValueNationalName(index);
              },
              "input": function input($event) {
                return _vm.inputHandle(passenger.PaxDocuments[0].isCountry);
              }
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref4) {
                var suggestion = _ref4.suggestion;
                return [_c('div', {
                  staticClass: "cursor-pointer"
                }, [_c('b-row', [_c('b-col', {
                  attrs: {
                    "cols": "12"
                  }
                }, [_c('b', [_vm._v(_vm._s(suggestion.item.countryName))])])], 1)], 1)];
              }
            }], null, true),
            model: {
              value: passenger.PaxDocuments[0].isCountry,
              callback: function callback($$v) {
                _vm.$set(passenger.PaxDocuments[0], "isCountry", $$v);
              },
              expression: "passenger.PaxDocuments[0].isCountry"
            }
          })];
        }
      }], null, true)
    })], 1), _c('b-col', {
      staticClass: "px-25 mt-50 px-lg-0",
      attrs: {
        "md": "3",
        "lg": "6"
      }
    }, [_c('validation-provider', {
      attrs: {
        "rules": _vm.getValidatePassport(),
        "name": _vm.$t('flight.expirationDate'),
        "vid": "passport-expirationDate-".concat(passenger.paxType, "-").concat(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-form-group', {
            staticClass: "mb-0",
            attrs: {
              "id": "passport-exDate-".concat(passenger.paxType, "-").concat(index)
            }
          }, [_c('flat-pickr', {
            staticClass: "d-none",
            model: {
              value: passenger.PaxDocuments[0].endDate,
              callback: function callback($$v) {
                _vm.$set(passenger.PaxDocuments[0], "endDate", $$v);
              },
              expression: "passenger.PaxDocuments[0].endDate"
            }
          }), _c('div', {
            staticClass: "form-control p-0",
            class: validationContext.errors[0] ? 'is-invalid' : ''
          }, [_c('flat-pickr', {
            staticClass: "form-control px-50 py-0",
            style: {
              height: '30px'
            },
            attrs: {
              "id": "passport-expirationDate-".concat(passenger.paxType, "-").concat(index),
              "name": _vm.$t('flight.expirationDate'),
              "config": Object.assign({}, _vm.configFlatPickrPassport, {
                altInputClass: 'form-control input px-50 py-0 border-transparent'
              }),
              "placeholder": "".concat(_vm.$t('flight.expirationDate'), " ").concat(_vm.getValidatePassport().includes('required') ? '(*)' : '')
            },
            model: {
              value: passenger.PaxDocuments[0].endDate,
              callback: function callback($$v) {
                _vm.$set(passenger.PaxDocuments[0], "endDate", $$v);
              },
              expression: "passenger.PaxDocuments[0].endDate"
            }
          })], 1), validationContext.errors[0] ? _c('b-tooltip', {
            attrs: {
              "target": "passport-exDate-".concat(passenger.paxType, "-").concat(index),
              "triggers": "hover focus",
              "placement": "top",
              "boundary": "viewport",
              "variant": "danger",
              "title": validationContext.errors[0]
            }
          }, [_c('span', {
            staticClass: "text-white py-25 mb-0"
          }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
        }
      }], null, true)
    })], 1)], 1) : _vm._e()], 1)], 1);
  }), _c('MembershipCardModal', {
    attrs: {
      "member-fly-data": _vm.memberFlyData,
      "data-search-flight": _vm.dataSearchFlight,
      "pax-data": _vm.paxDataSelect,
      "airline-lists": ['VN'],
      "place": 'create_booking'
    },
    on: {
      "update:memberFlyData": function updateMemberFlyData($event) {
        _vm.memberFlyData = $event;
      },
      "update:member-fly-data": function updateMemberFlyData($event) {
        _vm.memberFlyData = $event;
      }
    }
  }), _c('ModalQuickInputMultiPax', {
    attrs: {
      "max-adults": _vm.dataSearchFlight.adult,
      "max-children": _vm.dataSearchFlight.child,
      "max-infants": _vm.dataSearchFlight.infant
    },
    on: {
      "addMuliplePassengers": _vm.addMultiplePassengers
    }
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }